import { Button, Divider, Drawer, Space, Switch, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import AdminLayout from './AdminLayout'
import { httpDeleteAdmin, httpGetAdmin, httpPutAdmin } from '../../services/http'

function ActiveSwitch({ memo, isActiveAtStart }) {
    const [isActive, setIsActive] = useState(isActiveAtStart)
    const [loading, setLoading] = useState(false)

    async function switchSwitch() {
        setLoading(true)
        const res = await httpPutAdmin<{ is_active_now: boolean }>(
            `/ll-admin/auto-emails/${memo}`,
            { active: !isActive }
        )
        if (res.status === 200) {
            setIsActive(res.data.is_active_now)
        }
        setLoading(false)
    }

    return <Switch loading={loading} checked={isActive} onChange={switchSwitch} />
}

export default function AutoEmails() {
    const [autoEmails, setAutoEmails] = useState({ configured: [], activated: [] })
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [reloading, setReloading] = useState(false)
    const [selectedMail, setSelectedMail] = useState(null)

    async function fetchAutoEmails() {
        const res = await httpGetAdmin<any>('/ll-admin/auto-emails')
        setAutoEmails(res.data)
    }

    async function openDrawer(autoMail) {
        const res = await httpGetAdmin<any>(`/ll-admin/auto-emails/${autoMail.memo}/users`)
        setSelectedMail({ meta: autoMail, users: res.data.users })
        setDrawerOpen(true)
    }

    async function reloadCronJobs() {
        setReloading(true)
        await httpPutAdmin('/ll-admin/auto-emails', {}) // reload ep
        await fetchAutoEmails()
        setReloading(false)
    }

    async function deleteJob(memo) {
        setReloading(true)
        await httpDeleteAdmin(`/ll-admin/auto-emails/date-job/${memo}`, {}) // reload ep
        await fetchAutoEmails()
        setReloading(false)
    }

    useEffect(() => {
        fetchAutoEmails()
    }, [])

    return (
        <AdminLayout>
            <div style={{ margin: 30 }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <h1 style={{ fontSize: 25 }}>Configured jobs in Database:</h1>
                    <div style={{ width: '100%' }}>
                        <Divider />
                    </div>
                    {autoEmails.configured.map((ae) => {
                        return (
                            <div key={ae.memo} style={{ width: '100%' }}>
                                <Space size="large">
                                    <div className="auto-email-item">
                                        Memo: <span style={{ fontWeight: 'bold' }}>{ae.memo}</span>
                                    </div>
                                    <div className="auto-email-item">
                                        Is Active:{' '}
                                        <ActiveSwitch
                                            memo={ae.memo}
                                            isActiveAtStart={ae.is_active}
                                        />
                                    </div>
                                    <div className="auto-email-item">
                                        Active users:{' '}
                                        <span style={{ fontWeight: 'bold' }}>
                                            {ae.active_count}
                                        </span>
                                    </div>
                                    <Button onClick={() => openDrawer(ae)}>Users</Button>
                                    <div className="auto-email-item">
                                        Tags:{' '}
                                        {ae.tags
                                            .split(' ')
                                            .filter((t) => t.length > 0)
                                            .map((t, idx) => {
                                                return <Tag key={idx}>{t}</Tag>
                                            })}
                                    </div>
                                </Space>
                                <Divider />
                            </div>
                        )
                    })}
                    <br />
                    <br />
                    <Space>
                        <h1 style={{ fontSize: 25 }}>Jobs actually activated on server</h1>
                        <Button type="primary" loading={reloading} onClick={reloadCronJobs}>
                            Reload cron jobs
                        </Button>
                    </Space>
                    <div style={{ width: '100%' }}>
                        <Divider />
                    </div>
                    {autoEmails.activated.map((ae) => {
                        return (
                            <div key={ae.name} style={{ width: '100%' }}>
                                <Space>
                                    <div className="auto-email-item">
                                        <span style={{ fontWeight: 'bold' }}>{ae.readable}</span>
                                        {ae.type === 'date' && (
                                            <>
                                                <br />
                                                {ae.user_email && (
                                                    <>
                                                        <p>email: {ae.user_email}</p>
                                                    </>
                                                )}
                                                <Button onClick={() => deleteJob(ae.id)} danger>
                                                    Remove
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </Space>
                                <Divider />
                            </div>
                        )
                    })}
                </Space>
            </div>
            <Drawer
                title={selectedMail?.meta?.memo}
                width={300}
                placement="right"
                closable={false}
                onClose={() => setDrawerOpen(false)}
                visible={drawerOpen}
            >
                {selectedMail?.users?.map((u) => {
                    return (
                        <div key={u.id} style={{ marginBottom: 5 }}>
                            <p>Name: {u.name}</p>
                            <p>Email: {u.email}</p>
                            <p>
                                <Tag color={u.has_unsubscribed_newsletter ? 'error' : 'success'}>
                                    {u.has_unsubscribed_newsletter ? 'unsubscribed' : 'subscribed'}
                                </Tag>
                            </p>
                            <p>
                                Email verified:{' '}
                                <Tag color={u.email_verified ? 'success' : 'error'}>
                                    {u.email_verified ? 'Yes' : 'No'}
                                </Tag>
                            </p>
                            <Button
                                onClick={() => {
                                    navigate(`/ll-admin/ll-user/${u.id}`)
                                }}
                            >
                                View entire user
                            </Button>
                            <Divider />
                        </div>
                    )
                })}
            </Drawer>
        </AdminLayout>
    )
}
